import {
  faDiscord,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"

// Homepage Form Icons
export const icons = [
  { icon: faDiscord, color: "#8867ff", link: "https://discord.gg/masterbrews" },
  { icon: faTwitter, color: "#62b0e5", link: "https://twitter.com/MasterBrewsNFT" },
]

export const homeOddsData = [
  {
    'oddImg': '/homepage/new/whelps-01.png',
    'oddPrice': '1 in 10',
    'color': '#19B555'
  },
  {
    'oddImg': '/homepage/new/free-pack-01.png',
    'oddPrice': '1 in 25',
    'color': '#AEE8E6'
  },
  {
    'oddImg': '/homepage/new/distributor-01.png',
    'oddPrice': '1 in 35',
    'color': '#FAFAFA'
  },
  {
    'oddImg': '/homepage/new/masterbrew-01.png',
    'oddPrice': '1 in 173',
    'color': '#FEF379'
  },
  {
    'oddImg': '/homepage/new/mckenna-01.png',
    'oddPrice': '1 in 715',
    'color': '#0294B8'
  },
  {
    'oddImg': '/homepage/new/pls.png',
    'oddPrice': '1 in 10,000',
    'color': '#99FFC7'
  },
]

// Homepage Steps Data
export const steps = [
  {
    name: "Step 01",
    title: "Web3 Ethereum Wallet",
    link: "https://metamask.io/",
    linktext: "https://metamask.io/",
    desc:
      "If you don't already have one, please install a web3-wallet which supports Ethereum mainnet. We recommend MetaMask which can be installed in seconds from their official website:",
  },
  {
    name: "Step 02",
    title: "Buy or Deposit ETH",
    link: "",
    linktext: "",
    desc:
      "You will require enough ETH in your wallet to cover any purchases you wish to make, plus a small gas fee. Please fund your wallet in advance, to avoid missing out during our sale, as quantity will move fast!",
  },
  {
    name: "Step 03",
    title: "Connect to MasterBrews",
    link: "",
    linktext: "",
    desc:
      "Clicking CONNECT (found in our main menu), you will be prompted to select your account and confirm you wish to proceed. We recommend performing this step ASAP, so you are ready and experience no delays once the sale begins!",
  },
  {
    name: "Step 04",
    title: "Ready to Buy",
    link: "",
    linktext: "",
    desc:
      "You're all set! Once the sale begins, click the BUY button of your choice and confirm the transaction. Depending on network congestion, it may take a few moments, but you will receive a successful transaction confirmation.",
  },
  {
    name: "Step 05",
    title: "What Happens Next?",
    link: "",
    linktext: "",
    desc:
      "Sit back and relax, and smile at your wise decision! We will be posting daily updates, announcements, contests, art reveals and more throughout the entire sale period.",
  },
  {
    name: "Step 06",
    title: "Share with Friends",
    link: "",
    linktext: "",
    desc:
      "Don't keep this to yourself! We would love and appreciate all efforts which help spread our vision and project, and further build our awesome community. We shall all reap the benefits.",
  },
  {
    name: "Step 07",
    title: "Post-Sale",
    link: "",
    linktext: "",
    desc:
      "Once the sale ends, all card art and details will be determined and revealed. All final NFT MetaData will be set and viewable on our \"My BREWs\" page, marketplaces and your wallet.",
  },
  {
    name: "Step 08",
    title: "Still Need Help?",
    link: "https://discord.gg/masterbrews",
    linktext: "MasterBrews Discord",
    desc:
      "If you have any issues or need further help, please don't hesitate to reach out to us - the best way being through our Official",
  },
]

export const WhatsNextData = [
  {
    icon: "/homepage/ico_slipstream.png",
    desc: "P2E, Battle Racing and Dragons? Argentics Studio has our backs.",
    link: "https://whelps.io/",
    symbol: "/icons/icon_polygon.svg",
    style: {
      maxWidth: "85%"
    }
  },
  {
    icon: "/homepage/ico_heroes.svg",
    desc: "What makes a Hero or Villain? Marvel/DC legend, Mark McKenna, knows.",
    link: "https://heroesandvillains.io/",
    symbol: "/icons/icon_solana.svg",
    style: {
      maxWidth: "60%"
    }
  },
  {
    icon: "/homepage/ico_lorem.png",
    desc: "When Ragnarök is upon us, who will you fight for? Valhalla awaits!",
    link: "https://rrok.game/",
    symbol: "/icons/icon_eth.svg",
    style: {
      maxWidth: "90%"
    }
  },
  {
    icon: "/homepage/ico_dummy.png",
    desc: "Unlock the true power of Initial Game Offerings with Gauntlet.",
    link: "#",
    style: {
      maxWidth: "90%"
    }
  },
  {
    icon: "/homepage/ico_rabbit.png",
    desc: "Exclusive tech partner for THE GATE (White Rabbit, Pocket Rockets).",
    link: "https://rabbitff.com",
    symbol: "/icons/icon_eth.svg",
    style: {
      maxWidth: "75%"
    }
  },
  {
    icon: "/homepage/ico_koin.png",
    desc: "Exclusive tech partner for Koin Games Dev Squad (Bloodlines P2E).",
    link: "https://koingames.io/",
    symbol: "/icons/icon_eth.svg",
    style: {
      maxWidth: "60%"
    }
  },
  {
    icon: "/homepage/ico_cwc.png",
    desc: "Navigate the vast crypto sea under the watchful eye of Captain Ahab.",
    link: "https://cryptowhaleclub.io/",
    symbol: "/icons/icon_eth.svg",
    style: {
      maxWidth: "60%"
    }
  },
  {
    icon: "/homepage/ico_confuzed.png",
    desc: "Chaos reigns in this zany multi-player card game. Are you CONFUZED?",
    link: "#",
    style: {
      maxWidth: "60%"
    }
  }
]

export const TestimonialsData = [
  {
    avatar:"/testimonials/avatar_1.png",
    name:"crypto whales club",
    role:"nighthawk, founder",
    star:5,
    twitter:"https://twitter.com/cryptowhaleclub",
    feedback:"We utilized MasterBrews as our tech partner for the Crypto Whale Club and have been happy with the results. Because of them we have some of the most gas efficient contracts around and have been able to deploy a variety of utility driven features we otherwise wouldn't have. They possess a great depth of knowledge and are constantly pushing the boundaries on innovation."
  },
  {
    avatar:"/testimonials/avatar_2.png",
    name:"koin game dev squad",
    role:"tim jooste, founder",
    star:5,
    twitter:"https://twitter.com/koingamesio",
    feedback:"I have had the pleasure of consulting with the MasterBrews teams for months before we formally formed a partnership. The entire team has been incredibly easy to work with and extremely hard working. We have spent many long nights collaborating and very appreciative for the time and dedication they have given to us."
  },
  {
    avatar:"/testimonials/avatar_3.png",
    name:"heroes & villains",
    role:"mark mckenna, creator",
    star:5,
    twitter:"https://twitter.com/markmckenna419",
    feedback:"As a long time mainstream comic book creator, my leaping into the NFT/crypto art market is relatively in its infancy. Im truly fortunate to have the Masterbrews hierarchy do a lot of hand-holding and support the learning curve that allows me to become successful in this marketplace. Theyre always accessible to me and and I feel we've developed an easy friendship along the way."
  },
  {
    avatar:"/testimonials/avatar_4.png",
    name:"white rabbit",
    role:"shimecki, founder",
    star:5,
    twitter:"https://twitter.com/WhiteRabbitGATE",
    feedback:"We interviewed a lot of tech providers and MasterBrews were the only ones who not only answered all of our questions promptly, but also went above and beyond by suggesting how we can do things better. We are extremely pleased we decided to work with them as our launch went off without a hitch!"
  }
]
