import React from 'react'
import { WhatsNextData } from '@util/homeCardData'
import "./HomePage.scss"

export default function HomePage() {
  const Title = ({ className, children }) => {
    return (
      <p className={`smart-text text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl uppercase  font-thin tracking-wider ${className}`}>
        {children}
      </p>
    )
  }

  const Description = ({ className, children }) => {
    return (
      <p className={`text-lg sm:text-xl xl:text-2xl leading-7 sm:leading-9 xl:leading-10 text-white font-montserrat font-semibold ${className}`}>
        {children}
      </p>
    )
  }

  const LinkButton = ({ className, children }) => {
    return (
      <button className={`uppercase border-2 border-primary-light rounded-xl py-1 text-sm sm:text-base md:text-lg 2xl:text-2xl font-semibold text-center whitespace-nowrap ${className}`}>
        {children}
      </button>
    )
  }

  return (
    <div className='home-container'>
      <div className='intro min-h-screen flex flex-col items-center justify-center smart-text font-bold'>
        <div className='max-w-wide justify-center flex flex-col mx-auto items-center gap-6 sm:gap-4 px-2'>
          <img src="/homepage/logo_mb.png" alt="masterbrews" className='w-full lg:w-10/12 sm:mt-8 hidden sm:block' />
          <img src="/homepage/logo_mb_phone.png" alt="masterbrews" className='w-full lg:w-10/12 sm:mt-8 sm:hidden' />
          <Title className="sm:mt-5 text-center">
            smart people. smarter contracts.
          </Title>
          <div className='sm:flex-col-reverse flex flex-col justify-center items-center sm:mt-2'>
            <img src="/homepage/logo_silver.png" alt='silver logo' className='sm:mt-10 w-3/4 sm:w-auto' />
            <p className='uppercase text-center text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl transform -translate-y-12 sm:translate-y-0 px-6'>
              powering the future of web3 technology
            </p>
          </div>
        </div>
      </div>
      <div className='initiative min-h-screen px-4 py-10 flex items-center '>
        <div className='max-w-wide justify-center flex flex-col lg:flex-row mx-auto items-center gap-10 sm:gap-4'>
          <div className='flex flex-col w-full lg:w-1/2 sm:w-10/12 text-white gap-8 text-center sm:text-left sm:pl-12'>
            <Title>
              PUSHING THE ENVELOPE
            </Title>
            <Description className="text-center sm:text-left">
              Our entire team strongly believes that Web3 is the future and that future, is now. From our intense drive to innovate, WhelpS was born; introducing the Ethereum blockchain to on-chain, provably random, "evolving" NFTs. A quick sellout, WhelpS continues to forge ahead, having migrated seamlessly to Polygon and, in the coming months, will release their first AAA-quality P2E game: Slipstream.
            </Description>
            <a href='https://whelps.io/' target="_blank" rel="noreferrer">
              <LinkButton className=' px-8 '>
                WhelpS website
              </LinkButton>
            </a>
            <Description className="text-center sm:text-left">
              In the Fall of 2021, we expanded our skillset to the Solana blockchain with Mark McKenna's Heroes & Villains: Origins. We pushed "NFT Evolution" further by adding new twists and turns to the mechanic. Barely a few months old, we have successfully released a 100% on-chain staking program and are about to announce our P2E video game plans.
            </Description>
            <a href='https://heroesandvillains.io/' target="_blank" rel="noreferrer">
              <LinkButton className=' px-8 '>
                H&V website
              </LinkButton>
            </a>
          </div>
          <div className='w-full sm:w-1/2'>
            <img src="/homepage/whelps_phone.png" alt='whelps' className='w-10/12 mx-auto' />
          </div>
        </div>
      </div>
      <div className='whats-next min-h-screen flex flex-col items-center justify-center px-4 py-12'>
        <Title className="mt-10">
          OUR PORTFOLIO
        </Title>
        <Description className='sm:w-2/3 md:w-1/2 mt-8 text-center'>
          The success of WhelpS brought us several amazing partnership opportunities and we are excited for what the future holds!
        </Description>
        <div className='grid xs:grid-cols-2 gap-y-32 xl:grid-cols-4  gap-8 mt-44'>
          {
            WhatsNextData.map((data, idx) => (
              <div className='next-item relative pt-28 pb-4 text-white text-center px-2 flex flex-col max-w-xs' key={idx} >
                <div className='flex flex-col flex-1'>
                  <img src={data.icon} alt="whats next" className={`absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 top-0 `} style={data.style} />
                  <p className='flex-1 items-center justify-center flex md:text-lg xl:text-2xl'>
                    {data.desc}
                  </p>
                  <a href={data.link} target="_blank" rel="noreferrer">
                    <LinkButton className='px-6 mt-6'>
                      {data.link === "#" ? "coming soon" : "visit website"}
                    </LinkButton>
                  </a>
                </div>
                <div className="flex justify-center items-center mt-2 h-6">
                  {data.symbol && <img src={data.symbol} alt="symbol" className="h-full" />}
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <div className='community min-h-screen flex justify-center'>
        <div className='sm:container justify-center flex mx-auto items-start px-4 pb-60'>
          <div className='justify-center mt-12 relative md:w-1/2 w-full '>
            <img src="/homepage/img_left.png" alt='left' className='absolute md:block hidden w-1/2 transform translate-y-1/3 left-0 -translate-x-full bottom-0' />
            <div className='flex flex-col justify-center items-center text-white'>
              <Title>
                Our Community
              </Title>
              <Description className='mt-8 text-center'>
                We would not be where we are without our fantastic community, which includes our artists. They believed in our team and vision from the start and have stuck with us as we continue to adapt and pivot in a rapidly-changing space. For this, our sincere thanks.<br /><br />
                Purchasing our genesis MasterBrews collection not only aids artists worldwide (we have commissioned works from close to 60 countries), but allows supporters to share in our successes through our Dividend program; detailed below. We still have NFTs available for sale, however, we will be closing sales very soon, so act fast!
              </Description>
              <a href="/mint" target="_blank" rel="noreferrer">
                <LinkButton className='px-8 mt-6'>
                  Mint NFTs
                </LinkButton>
              </a>
              <div className='flex items-center justify-center py-12 md:hidden'>
                <div className='w-2/5 flex items-center justify-end'>
                  <img src="/homepage/img_left.png" alt='left-small' />
                </div>
                <div className='w-3/5 flex items-center justify-start'>
                  <img src="/homepage/img_right.png" alt='right-small' />
                </div>
              </div>
            </div>
            <img src="/homepage/img_right.png" alt='right' className='absolute md:block hidden w-1/2 transform translate-y-1/3 right-0 translate-x-full bottom-0' />
          </div>
        </div>
      </div>
      <div className='dividends min-h-screen flex justify-center py-12'>
        <div className='sm:container justify-center flex md:justify-start mx-auto px-4'>
          <div className='flex flex-col justify-center items-center'>
            <Title>
              Dividends!
            </Title>
            <Description className='mt-8 w-full xl:w-1/2 px-2 text-center'>
              We treat all MasterBrews NFT holders as investors. By allocating 5% of all revenue generated from internal and partner projects into our dividend pool smart contract, holders can claim when and how they like - for life! Tokens become eligible 30 days after their minting date.
            </Description>
            <img src='/homepage/silver_coin.png' alt='coin' className='mt-20 sm:mt-24 w-2/3 sm:w-1/2 md:w-1/3 xl:w-auto' />
          </div>
        </div>
      </div>
      <div className='partnership min-h-screen flex justify-center sm:justify-start relative'>
        <div className='sm:container justify-center flex md:justify-start mx-auto px-4'>
          <div className='flex flex-col  text-white justify-center sm:justify-start sm:items-start px-2 items-center w-full xs:w-11/12 lg:w-2/3 z-10 pt-96 lg:pt-8 pb-10'>
            <Title className="text-center sm:text-left lg:whitespace-nowrap sm:mt-12">
              Interest in working with us?
            </Title>
            <Description className='mt-2 text-center sm:text-left'>
              Finding a strong, experienced team to help your project succeed can be an incredibly difficult task and we would love to hear about your vision and how we may be able to help you turn it into a reality.<br /><br />
              While our stellar reputation and successful track record speaks for itself, you don't have to take our word for it! Read what past and current partners have to say by checking out our testimonials page:
            </Description>
            <a href="/testimonials" target="_blank" rel="noreferrer">
              <LinkButton className='px-8 mt-8'>
                READ TESTIMONIALS
              </LinkButton>
            </a>
            <Description className='mt-8 text-center sm:text-left'>
              If you would like to discuss your project, you can reach us directly at
            </Description>
            <a href="mailto:hello@masterbrews.cards" target="_blank" rel="noreferrer">
              <LinkButton className='px-8 mt-8'>
                HELLO@MASTERBREWS.CARDS
              </LinkButton>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
