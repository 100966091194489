// Core React
import Layout from "@components/layout/Layout/Layout"
import HomePage from "@partials/HomePage/HomePage"
import React from "react"

// Render
const IndexPage = () => (
  <Layout
    title="Masterbrews | Smart Contract, NFT & Blockchain Services & Consulting"
    contentClassName="homePageContent px-0 max-w-full"
  >
    <HomePage />
  </Layout>
)
export default IndexPage
